/* eslint-disable react/jsx-props-no-spreading, react/no-danger */
import classNames from 'classnames';
import PropTypes from 'prop-types';

// import React from 'react';

import Button from './partials/Button';

// import * as AppPropTypes from '../../../lib/PropTypes';
import styles from './styles/start.module.css';

const propTypes = {
    texts: PropTypes.shape({
        body: PropTypes.string,
        pagination: PropTypes.string,
        button: PropTypes.string,
        author: PropTypes.string,
    }),
    onNext: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    texts: {},
    onNext: null,
    className: null,
};

function Start({ texts, onNext, className }) {
    const { button = 'Commencer le quiz' } = texts;
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <Button className={styles.button} onClick={onNext}>
                {button}
            </Button>
        </div>
    );
}

Start.propTypes = propTypes;
Start.defaultProps = defaultProps;

export default Start;
