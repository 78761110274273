import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Replay from './icons/Replay';
import NextButton from './partials/Next';
import Pagination from './partials/Pagination';
import TextPartial from './partials/Text';

import styles from './styles/ending.module.css';

const propTypes = {
    theme: PropTypes.object.isRequired,
    finalResult: PropTypes.string,
    onRestart: PropTypes.func.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    finalResult: null,
    className: null,
};

function Ending({ finalResult = null, onRestart = null, className }) {
    if (finalResult === null) {
        return null;
    }
    const { title = null, body = null, topImage = null, background, color } = finalResult || {};

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.pagination}>
                <Pagination>
                    <FormattedMessage defaultMessage="Résultat" description="Button label" />
                </Pagination>
            </div>
            <div className={styles.top}>
                {topImage !== null ? (
                    <div className={styles.profile}>
                        <h3 className={styles.title}>
                            <FormattedMessage
                                defaultMessage="Ton profil est"
                                description="Button label"
                            />
                        </h3>
                        <img className={styles.topImage} src={topImage} alt="pastille" />
                    </div>
                ) : (
                    <h3 className={styles.title}>{title}</h3>
                )}
            </div>
            <div className={styles.inner}>
                <TextPartial
                    className={styles.description}
                    body={body}
                    style={{ background, color }}
                />
                <div className={styles.actions}>
                    <NextButton
                        className={styles.buttons}
                        onClick={onRestart}
                        icon={<Replay />}
                        label={
                            <FormattedMessage defaultMessage="Rejouer" description="Button label" />
                        }
                    />
                </div>
            </div>
        </div>
    );
}

Ending.propTypes = propTypes;
Ending.defaultProps = defaultProps;

export default Ending;
