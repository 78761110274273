/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';

import NextIcon from '../icons/Next';
import Button from './Button';

import styles from '../styles/partials/next.module.css';

const propTypes = {
    label: PropTypes.string,
    icon: PropTypes.node,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    label: 'Footer',
    icon: null,
    className: null,
};

function NextButton({ label, icon, onClick, className }) {
    return (
        <Button
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            onClick={onClick}
        >
            <span className={styles.label}>{label}</span>
            <span className={styles.icon}>{icon || <NextIcon />}</span>
        </Button>
    );
}

NextButton.propTypes = propTypes;
NextButton.defaultProps = defaultProps;

export default NextButton;
