/* eslint-disable react/prop-types */
const Replay = ({ className = null }) => (
    <svg
        width="28"
        height="32"
        viewBox="0 0 28 32"
        fill="none"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        className={className}
    >
        <path
            d="M1.86944 18.0837C2.41285 18.0824 2.86721 18.4897 2.91516 19.0214C3.39966 24.7523 8.30634 29.2207 14.2724 29.2207C20.555 29.2207 25.6717 24.2278 25.6717 18.09C25.6717 11.9522 20.7162 7.11142 14.567 6.95934C14.4154 6.95216 14.2866 7.06701 14.2797 7.21596C14.2797 7.22224 14.2797 7.22852 14.2797 7.23525L14.2797 10.7046C14.2797 11.5736 13.3039 12.0976 12.5532 11.6355L5.80025 6.99433C5.27511 6.67086 5.11574 5.99028 5.44498 5.47435C5.53494 5.33303 5.65641 5.21369 5.80025 5.12531L12.5458 0.546525C13.297 0.0844293 14.2724 0.608437 14.2724 1.47745L14.2724 4.62912C14.2697 4.77851 14.3907 4.90234 14.5428 4.90503C14.5437 4.90503 14.545 4.90503 14.546 4.90503C21.8742 5.04994 27.7705 10.8979 27.7705 18.0905C27.7705 25.283 21.7267 31.2692 14.2724 31.2692C7.19668 31.2692 1.39909 25.9797 0.823713 19.1941C0.775765 18.6297 1.20273 18.1344 1.77719 18.0873C1.80779 18.0846 1.83884 18.0837 1.86944 18.0837Z"
            fill="currentColor"
        />
    </svg>
);

export default Replay;
