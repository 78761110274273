/* eslint-disable react/jsx-props-no-spreading, react/no-danger */
import classNames from 'classnames';
import PropTypes from 'prop-types';

// import * as AppPropTypes from '../../../lib/PropTypes';
import styles from '../styles/partials/title.module.css';

const propTypes = {
    body: PropTypes.string,
    style: PropTypes.object, // eslint-disable-line
    className: PropTypes.string,
};

const defaultProps = {
    body: null,
    style: null,
    className: null,
};

const Title = ({ body, style, className }) => (
    <div
        className={classNames([
            styles.container,
            {
                [className]: className !== null,
            },
        ])}
        style={style}
        dangerouslySetInnerHTML={{ __html: body }}
    />
);

Title.propTypes = propTypes;
Title.defaultProps = defaultProps;

export default Title;
