import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

// import Next from './icons/Next';
import Answer from './partials/Answer';
import NextButton from './partials/Next';
import Pagination from './partials/Pagination';
import Title from './partials/Title';

import styles from './styles/question.module.css';

const propTypes = {
    qid: PropTypes.number.isRequired,
    currentIndex: PropTypes.number.isRequired,
    questionsCount: PropTypes.number,
    texts: PropTypes.object,
    answer: PropTypes.shape({}),
    answers: PropTypes.arrayOf(PropTypes.shape({})),
    onNext: PropTypes.func.isRequired,
    onAnswerQuestion: PropTypes.func.isRequired,
    delay: PropTypes.number,
    className: PropTypes.string,
};

const defaultProps = {
    questionsCount: 0,
    texts: null,
    answer: null,
    answers: [],
    delay: 0,
    className: null,
};

function Question({
    qid,
    questionsCount,
    currentIndex,
    texts,
    answer: currentAnswer,
    answers,
    onNext,
    onAnswerQuestion,
    delay,
    className,
}) {
    const { title = null } = texts || {};
    const { answerValue = null, index: answerIndex = null } = currentAnswer || {};
    const answered = answerIndex !== null && answerValue !== null;

    // Delay change
    const [hasAnswered, setHasAnswered] = useState(answerValue !== null);
    useEffect(() => {
        const id = setTimeout(() => {
            if (answerValue !== null) {
                setHasAnswered(true);
            } else {
                setHasAnswered(false);
            }
        }, delay);
        return () => {
            clearTimeout(id);
        };
    }, [delay, answerValue, currentIndex]);

    const isLastQuestion = currentIndex === questionsCount;

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.inner}>
                <div className={styles.pagination}>
                    <Pagination current={qid} total={questionsCount} />
                </div>
                <div className={styles.title}>
                    <Title body={title} className={styles.question} />
                </div>
                {answers.map((answer, i) => (
                    <Answer
                        {...answer}
                        key={`answer-${i + 1}`}
                        className={styles.answer}
                        letter={String.fromCharCode(97 + i)}
                        onClick={(value) => {
                            onAnswerQuestion({
                                name: qid,
                                value: answered ? null : value,
                                valid: true,
                                idx: i,
                            });
                        }}
                        open={hasAnswered && i === answerIndex}
                        // valid={answered && answer.value > 0}
                        // invalid={answered && answerIndex === i && answer.value < 1}
                        selected={answered && answerIndex === i}
                    />
                ))}
                {hasAnswered ? (
                    <div className={styles.actions}>
                        <NextButton
                            className={styles.buttons}
                            onClick={onNext}
                            label={
                                isLastQuestion ? (
                                    <FormattedMessage
                                        defaultMessage="Voir les résultats"
                                        description="Button label"
                                    />
                                ) : (
                                    <FormattedMessage
                                        defaultMessage="Question suivante"
                                        description="Button label"
                                    />
                                )
                            }
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
}

Question.propTypes = propTypes;
Question.defaultProps = defaultProps;

export default Question;
