/* eslint-disable react/prop-types */
const Next = ({ className = null }) => (
    <svg
        width="27"
        height="20"
        viewBox="0 0 27 20"
        fill="none"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        className={className}
    >
        <path
            d="M25.8003 11.102C26.3137 10.5886 26.3137 9.75617 25.8003 9.24276L17.4339 0.876347C16.9205 0.362943 16.0881 0.362943 15.5747 0.876347C15.0613 1.38975 15.0613 2.22215 15.5747 2.73555L23.0115 10.1724L15.5747 17.6092C15.0613 18.1226 15.0613 18.955 15.5747 19.4684C16.0881 19.9818 16.9205 19.9818 17.4339 19.4684L25.8003 11.102ZM0.870728 11.487H24.8707V8.85771H0.870728V11.487Z"
            fill="currentColor"
        />
    </svg>
);

export default Next;
