import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import styles from '../styles/partials/pagination.module.css';

const propTypes = {
    current: PropTypes.number,
    total: PropTypes.number,
    className: PropTypes.string,
    children: PropTypes.node,
};

const defaultProps = {
    current: null,
    total: 1,
    className: null,
    children: null,
};

function Pagination({ current, total, className, children }) {
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.inner}>
                {current !== null ? (
                    <span>
                        <FormattedMessage defaultMessage="Question" description="Quiz label" />{' '}
                        {current}/{total}
                    </span>
                ) : null}
                {children}
            </div>
        </div>
    );
}

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;

export default Pagination;
