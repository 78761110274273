import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from '../styles/partials/button.module.css';

const propTypes = {
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    children: PropTypes.node,
};

const defaultProps = {
    className: null,
    children: null,
};

function Button({ onClick, className, children }) {
    return (
        <button
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
            type="button"
            onClick={onClick}
        >
            {children}
        </button>
    );
}

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
