import * as Components from '../components';

import meia from '../img/cdpq/drinks/meia.png';
import rum from '../img/cdpq/drinks/rum.png';
import tonic from '../img/cdpq/drinks/tonic.png';
import wine from '../img/cdpq/drinks/wine.png';
import boreal from '../img/cdpq/pastilles/boreal.png';
import delicat from '../img/cdpq/pastilles/delicat.png';
import fruite from '../img/cdpq/pastilles/fruite.png';
import crabe from '../img/gaspesie/endings/crabe.png';
import hommePoisson from '../img/gaspesie/endings/homme-poisson.png';
import phare from '../img/gaspesie/endings/phare.png';
import route132 from '../img/gaspesie/slides/132.png';
import biere from '../img/gaspesie/slides/biere.png';
import chalet from '../img/gaspesie/slides/chalet.png';
import char from '../img/gaspesie/slides/char.png';
import ours from '../img/gaspesie/slides/ours.png';
import valise from '../img/gaspesie/slides/valise.png';

const backgroundImages = [[char], [valise], [route132], [chalet], [biere], [ours], [null]];

// const foregroundImages = [madFlare, lowFlare, madFlare, lowFlare, madFlare, lowFlare];

export default {
    id: 'gaspesie',
    title: 'Quiz Gaspésie',
    components: Components,
    theme: 'gaspesie',
    backgroundImages,

    getResult: (answers) => {
        const values = Object.keys(answers).reduce((acc, key) => {
            const { answerValue = null } = answers[key] || {};
            if (typeof acc[answerValue] === 'undefined') {
                acc[answerValue] = 0;
            }
            acc[answerValue] += 1;
            return acc;
        }, {});
        const winner = Object.keys(values).reduce((acc, key) => {
            const points = values[key];
            const { points: previousPoints = 0 } = acc || {};
            if (points >= previousPoints) {
                return { points, key };
            }
            return acc;
        }, 0);

        const { key = null } = winner || {};

        return { rank: key };
    },

    slides: [
        {
            type: 'Start',
            texts: {
                button: 'Commencer le quiz',
            },
        },
        {
            type: 'Question',
            qid: 1,
            texts: {
                title: '<p>Votre valise est presque pleine et il vous reste de la place pour un seul objet. Vous emportez…</p>',
            },
            answers: [
                {
                    value: 'P',
                    label: 'Un brûleur',
                    description:
                        '<p>Un arrêt à la <a target="_blank" rel="noopener noreferrer" href="https://www.tourisme-gaspesie.com/fr/experiences/mer/poissonneries-fumoirs.html">poissonnerie</a>, parce qu’un bon spag aux crevettes cuit dans l’eau de mer, ça dépanne toujours!</p>',
                    isBoolean: false,
                },
                {
                    value: 'D',
                    label: 'Rien! Vive le minimalisme.',
                    description:
                        '<p>Si vous changez d’idée, vous aurez de la place pour un chandail de la boutique <a target="_blank" rel="noopener noreferrer" href="https://www.tourisme-gaspesie.com/fr/visiter/boutique-le-macareux/7822414A.html">Le Macareux</a>.</p>',
                    isBoolean: false,
                },
                {
                    value: 'M',
                    label: 'Votre liseuse',
                    description: '<p>Amenez-en, de la route!</p>',
                    isBoolean: false,
                },
            ],
        },
        {
            type: 'Question',
            qid: 2,
            texts: {
                title: '<p>Vous embarquez sur la 132, direction la Gaspésie! Durant un <em>road trip</em>, vous êtes la personne qui…</p>',
            },
            answers: [
                {
                    value: 'M',
                    label: 'Cherche en ligne où réserver une table pour le souper',
                    description:
                        '<p>La journée se termine aux portes de la Gaspésie? Arrêtez-vous au restaurant <a target="_blank" rel="noopener noreferrer" href="https://www.tourisme-gaspesie.com/fr/visiter/projet-la-ruche/7720049.html">Projet La Ruche</a> ou au <a target="_blank" rel="noopener noreferrer" href="https://www.tourisme-gaspesie.com/fr/visiter/cargo/5662651A.html">Cargo</a>!</p>',
                    isBoolean: false,
                },
                {
                    value: 'D',
                    label: 'S’émerveille du paysage',
                    description:
                        '<p>Pour profiter encore plus des paysages de la Gaspésie, initiez-vous au parapente avec <a target="_blank" rel="noopener noreferrer" href="https://www.tourisme-gaspesie.com/fr/visiter/vue-du-ciel/7972025.html">Vue du Ciel</a>.</p>',
                    isBoolean: false,
                },
                {
                    value: 'P',
                    label: 'S’occupe de la musique, parce que l’ambiance est primordiale',
                    description:
                        '<p>Parlant d’ambiance, passez à la microbrasserie <a target="_blank" rel="noopener noreferrer" href="https://www.tourisme-gaspesie.com/fr/visiter/la-fabrique/5664020A.html">La Fabrique</a> pour une petite pause énergisante.</p>',
                    isBoolean: false,
                },
            ],
        },
        {
            type: 'Question',
            qid: 3,
            texts: {
                title: '<p>Pour vous, la façon idéale de s’endormir, c’est…</p>',
            },
            answers: [
                {
                    value: 'M',
                    label: 'Au bord d’un feu, sur le bord de la mer, avant de se traîner jusqu’à un lit',
                    description:
                        '<p>Passer en mode chalet aux <a target="_blank" rel="noopener noreferrer" href="https://www.tourisme-gaspesie.com/fr/visiter/chalets-du-bioparc-de-la-gaspesie/5341997A.html">chalets du Bioparc</a>, à Bonaventure, ça vous dit?</p>',
                    isBoolean: false,
                },
                {
                    value: 'P',
                    label: 'En jasant jusqu’aux petites heures dans un dortoir du <a target="_blank" rel="noopener noreferrer" href="https://www.tourisme-gaspesie.com/fr/visiter/auberge-festive-sea-shack/7632999.html">Sea Shack</a>',
                    description: '<p>Un camp de vacances version adulte!</p>',
                    isBoolean: false,
                },
                {
                    value: 'D',
                    label: 'Dans une tente : il n’y a rien de mieux!',
                    description:
                        '<p>On vous entend déjà dire : « Respirer de l’air frais en dormant, ça énergise! »</p>',
                    isBoolean: false,
                },
            ],
        },
        {
            type: 'Question',
            qid: 4,
            texts: {
                title: '<p>Quand quelqu’un vous demande si vous voulez quelque chose à boire, vous répondez :</p>',
            },
            answers: [
                {
                    value: 'D',
                    label: '« Non merci, j’ai surtout soif d’aventure! »',
                    description:
                        '<p>Les températures du printemps sont parfaites pour les randos, mais n’oubliez pas votre bouteille d’eau!</p>',
                    isBoolean: false,
                },
                {
                    value: 'P',
                    label: '« Il est cinq heures quelque part! »',
                    description:
                        '<p><a target="_blank" rel="noopener noreferrer" href="https://www.tourisme-gaspesie.com/fr/visiter/microbrasserie-au-frontibus/3605153.html">Frontibus</a>, <a target="_blank" rel="noopener noreferrer" href="https://www.tourisme-gaspesie.com/fr/visiter/pub-pit-caribou/7821443.html">Pit Caribou</a>, <a target="_blank" rel="noopener noreferrer" href="https://www.tourisme-gaspesie.com/fr/visiter/microbrasserie-le-naufrageur/3645440.html">Le Naufrageur</a> ou <a target="_blank" rel="noopener noreferrer" href="https://www.tourisme-gaspesie.com/fr/visiter/microbrasserie-la-captive/6311343.html">La Captive</a> : ce n’est pas le choix qui manque!</p>',
                    isBoolean: false,
                },
                {
                    value: 'M',
                    label: '« Cinq heures, c’est-tu trop tard pour un dernier café? »',
                    description:
                        '<p>Peu importe l’heure, il y a toujours quelque chose à manger au <a target="_blank" rel="noopener noreferrer" href="https://www.tourisme-gaspesie.com/fr/visiter/l-amarre/7972323R.html">café L’Amarré</a>, à Saint-Maxime-du-Mont-Louis.</p>',
                    isBoolean: false,
                },
            ],
        },
        {
            type: 'Question',
            qid: 5,
            texts: {
                title: '<p>Pour vous, des sensations fortes, c’est…</p>',
            },
            answers: [
                {
                    value: 'P',
                    label: 'Voyager dans le temps et dans l’histoire',
                    description:
                        '<p>Plongez dans l’histoire de la Gaspésie grâce à l’exposition en réalité virtuelle du <a target="_blank" rel="noopener noreferrer" href="https://www.tourisme-gaspesie.com/fr/visiter/musee-de-la-gaspesie/3681534.html">Musée de la Gaspésie</a>.</p>',
                    isBoolean: false,
                },
                {
                    value: 'M',
                    label: 'Le moins souvent possible',
                    description:
                        '<p>Pour émerveiller petits et grands, direction les fabuleux <a target="_blank" rel="noopener noreferrer" href="https://www.tourisme-gaspesie.com/fr/visiter/jardins-de-metis/7752222.html">Jardins de Métis</a>.</p>',
                    isBoolean: false,
                },
                {
                    value: 'D',
                    label: 'Le matin : c’est meilleur que le café pour se réveiller!',
                    description:
                        '<p>Du kayak à travers les glaces, ça vous dit? C’est possible, avec <a target="_blank" rel="noopener noreferrer" href="https://www.tourisme-gaspesie.com/fr/visiter/cap-aventure/8925055.html">Cap Aventure</a> à Gaspé!</p>',
                    isBoolean: false,
                },
            ],
        },
        {
            type: 'Ending',
            texts: {
                body: null,
            },
            results: {
                P: {
                    title: 'Soirées et crustacés',
                    body: '<p>Comme le crabe des neiges, vous attendez votre saison avant de sortir? Que vous préfériez les soirées qui s’éternisent ou les réveils matinaux, la Gaspésie regorge de restaurants, de brasseries et d’événements culturels où passer des moments inoubliables entre amis. Au printemps, profitez de la saison des crevettes et du crabe pour vous remplir la panse!</p><p><a href="https://www.tourisme-gaspesie.com/fr/decouvrir/saisons/printemps.html" target="_blank" rel="noopener noreferrer">Vous envisagez de visiter la Gaspésie ce printemps</a>? Assurez-vous de vérifier d’abord les différentes dates d’ouverture des attraits que vous souhaitez explorer!</p>',
                    image: crabe,
                    topImage: null,
                    background: '#C0D9F4',
                    color: '#212038',
                },
                D: {
                    title: 'Poisson et ascension',
                    body: '<p>Pour vous, le territoire gaspésien est synonyme non seulement de beaux panoramas, mais aussi de terrain de jeu! Dignes d’un surf and turf, vos activités préférées varient du ski de printemps dans les Chic-Chocs à la pêche au saumon dans la Bonaventure.</p><p><a href="https://www.tourisme-gaspesie.com/fr/quoi-faire/aventures-en-nature.html" target="_blank" rel="noopener noreferrer">Vous envisagez de visiter la Gaspésie ce printemps</a>? Assurez-vous de vérifier d’abord les différentes dates d’ouverture des attraits que vous souhaitez explorer!</p>',
                    image: hommePoisson,
                    topImage: null,
                    background: '#00A9CA',
                    color: '#FFEECC',
                },
                M: {
                    title: 'Plages et paysages',
                    body: '<p>En famille ou en couple, la Gaspésie est l’endroit idéal pour recharger ses batteries. Aux paysages qui n’ont d’égal que les villages pittoresques s’ajoutent les visites de phares, les promenades sur la plage et les bonnes tables.</p><p><a href="https://www.tourisme-gaspesie.com/fr/experiences/mer/plages.html" target="_blank" rel="noopener noreferrer">Vous envisagez de visiter la Gaspésie ce printemps</a>? Assurez-vous de vérifier d’abord les différentes dates d’ouverture des attraits que vous souhaitez explorer!</p>',
                    image: phare,
                    topImage: null,
                    background: '#212038',
                    color: '#C0D9F4',
                },
            },
        },
    ],
};
